@font-face {
  font-family: norwester;
  src: url('./fonts/norwester.otf');
}

p {
  margin: 0;
}

.container-bacardi-outer {
  width: 100vw;
  height: 100vh;
  background-color: #b92e32;
}

.container-bacardi {
  position: fixed;
  /* 16:9 aspect ratio and centered: */
  /* why calculations? https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  margin-left: calc(50vw - 88.888888 * var(--vh, 1vh));
  height: calc(var(--vh, 1vh) * 100);
  width: calc(177.777777 * var(--vh, 1vh));
}

.container-portrait {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: beige;
  flex-direction: column;
}

.terms-button {
  margin-left: 5px;
  margin-right: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}
