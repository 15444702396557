.customNavBar{
    text-align: center;
    margin-top:3%;
    margin-bottom: 3%;
    z-index: 20;
}
.navbarItems li {
    padding-left: 2%;
    list-style: none;
}
.Navbar-Link-li{
    font-family: Gosha, serif;
    list-style: none;
    font-size: 20px;
}
.mainRow {
    /* background-color: royalblue; */
    align-items: center;
}
.mainLogo img{
    /* background-color: saddlebrown; */
    margin-right: 10%;
}
#revealIcon{
    height: 30px;
}
.navbarItems a{
    padding:10px
}
.Navbar-Link-li a:hover{
    border-bottom: 1px solid black;
}
.revealItems {
    z-index: 12;
    background-color: #ffdd46;
    position: fixed;
    top:0;
    /* margin: 10px; */
    padding: 0;
    height: 100%;
    z-index: 11;
}
.align-content {
    justify-content: center;
    padding-bottom: 10%;
}

.mobNavbar{
    display:none;
}
.over-the-years-active a{
    background-color: #0098bc;
    border-radius: 1000px;
    color:white;
    border: 1px solid black;

}
.contact-us-active a{
    background-color: #a668ba;
    border-radius: 1000px;
    /* color:white; */
    border: 1px solid black;
}
.buy-ticket-active a{
    background-color: #30b88d;
    color:white;
    border-radius: 1000px;
    border: 1px solid black;
}
.lineup-active a {
    background-color: #ffdd46;
    color:black;
    border-radius: 10000px;
    border: 1px solid black;
}
.faq-active a{
    background-color: #ff6746;
    color:white;
    border-radius: 10000px;
    border: 1px solid black;

}
@media only screen and (max-width: 782px) {
    .Navbar-Link-li a{
        padding-left: 2px;
        font-size: 19px;
    }
    #comingSoonDiv {
        color:red;
        margin-left: -50%;
    }
    .mobNavbar{
        left: 0;
        display: block;
    }
   .item-list {
       display: none;
   }
   .mainLogo {
    text-align: start;

    margin-left: 10%;
    /* padding-left: 10%; */
   }
}

@media only screen and (max-width: 890px) {
    .mainLogo {
        padding: 2px;
    }
    .Navbar-Link-li a{
        padding-left: 2px;
        font-size: 18px;
    }
    
}


@media only screen and (max-width: 769px) {
    .Navbar-Link-li a{
        padding: 10px;
    }
    .customNavBar{
        z-index: 12;
    }
}

