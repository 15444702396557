.vidItem, .vidItem-first{
    /* text-align: 'center'; */
    /* margin-left: 26%; */
    /* ali */
    /* background-color: orange; */
    /* align-self: center; */
    margin:0 auto;
    display: block;
    /* margin:0; */
    width: 35%;
    text-align: center;
    font-family: Gosha, serif;
    position: relative;
    margin-bottom: 90px;
    /* top:-150px; */
    letter-spacing: 0.05em;
}
.headingSection{
    /* background-color: yellow; */
    position: relative;
}
.OverTheYearsHeadBlck {
    /* float: left; */
    /* background-color: blue; */
    width: 70%;
    display: inline-block;
    position: relative;
}
.OverTheYearsHeadImg {
    /* display: inline-block; */
    /* position: absolute; */
    float: right;
    /* margin-top:-10%; */
    position: relative;
    width:30%;
    /* z-index: -1; */
    /* background-color: green; */
}
#overTheYearsContent td{
    border: 1px solid black;

}
#overTheYearsContent table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#overTheYearsContent #tableContainer{
    /* background-color: red; */
    align-items: right;
    position: absolute;
    text-align: right;
}
.OverTheYearsHeading{
    padding: 5% 0;
    /* top:-200px; */
    color:white;
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    font-size: 86px;
    margin:0;
    font-family: Balgin, serif;
    text-align: right;
    /* margin-left: 10%; */
    /* position: absolute; */
    /* marg */
    /* background-color: red; */
    /* display: inline-block; */
}
/* .vidItem{
    position: relative;
} */
.SideImg{
    /* position: relative; */
    /* float:right; */
    /* left: 65%; */
    /* display:inline-block; */
    width:95%;
    /* height:40%; */
    /* z-index: -1; */
    /* top:20px; */
    /* right: 0; */
    /* align-items: right;
    
    align-self: ri; */
}
.ContentSection{
    align-items: 'center';
    /* background-color: red;/s */
    align-content: center;
    /* background: url('../images/vertical_grid.png');
    background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* background-position: right 120% bottom; */
    /* background-position: top -20% left 1050%;
    background-position: absolute; */
    /* align */
    /* al */
}
.YoutubePlayer{
    height: 350px;
    width: 100%;
}
@media only screen and (max-width: 1094px){

    .OverTheYearsHeading{
        font-size: 62px;
    }
}

@media only screen and (max-width: 768px){
    
    .vidItem, .vidItem-first{
        /* text-align: 'center'; */
        /* margin-left: 26%; */
        /* ali */
        /* background-color: orange; */
        /* align-self: center; */
        margin:0 auto;
        display: block;
        /* margin:0; */
        width: 70%;
    }
}
@media only screen and (max-width: 426px){
    .YoutubePlayer{
        height: 150px;
        width: 100%;
    }
    .OverTheYearsHeading{
        font-size: 32px;
        text-align: left;
        margin-left: 35px;
    }
    .SideImg{
        position: absolute;
        right: 1%;
        width: 200px;
    }
    .ContentSection{
        margin-top: 30%;
    }
}