a{
    text-decoration: none;
    color:black
}
.TicketImg{
    display: inline-block;
    width: 100%;
    /* height:400px;
    width: 300px; */
    margin:10px;
    font-family: 'Balgin';

}
.BuyTicketContent{
    width:80%;
    margin:auto;
    /* background-color: blue; */
}
.TicketBlck{
    /* display: inline-block; */
    position: relative;
    /* top:-140; */
    width: 23%;

    margin:10px;
    /* background-color: green; */
    margin:10px;
}
.TicketBlck:hover {
    /* width: 23.2%; */
    transform: scale(1.1);
}
#BuyTicketContent td{
    border: 1px solid black;

}
#BuyTicketContent table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#BuyTicketContent #tableContainer{
    /* background-color: red; */
    align-items: right;
    position: absolute;
    text-align: right;
    z-index: -1;
}
.BuyTicketsContent{
    /* background: url('../images/grid.png'); */
    position: relative;
    /* background: url('../images/grid.png');
    background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    /* background-position: right 120% bottom; */
    /* background-position: top -10% left -20%; */
    /* background-size: 80% 100%; */
    /* background-repeat: repeat-y; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 80%;/ */
    margin: auto;
    margin-bottom: 20%;
    /* margin-left: 10%; */
}
.BuyTicketsHeading{
    position: relative;
}
.BuyTicketsHead{
    /* background-color: yellow; */
    height: 200px;
    position: relative;
}
.BuyTicketHeadTitleBlck{
    /* float: left; */
    display:inline-block;
    width: 70%;
    /* margin: auto; */
    /* background-color: red; */
}
.BTHeadImgBlck {
    /* display: inline-block; */
    float: right;
    /* background-color: green; */
    width:25%;
}
.BTTitleBlck {
    /* background-color: purple; */
    float: left;
    /* width: 60%; */
    /* margin-left: 15%; */
    
    margin-right: 2%;
}
.BTTitle {
    font-family: Balgin, serif;
    /* font-size: 350%; */
    font-size: 85px;
    color: white;
    /* text-shadow: 2px 2px black; */
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;


}
.SideImgBuyTicket {
    width: 9%;
    margin-left: 17%;
    /* background-color: blue; */
    z-index: 100;
    position: absolute;
    /* bottom:1px; */
    /* top:-1px; */
}
.SideImgbo{
    position: relative;
    /* float:right; */
    left: 65%;
    display:inline-block;
    width:15%;
    height:40%;
    /* z-index: -1; */
    /* top:50px; */
    right: 0;
    /* align-items: right;
    
    align-self: ri; */
}

@media only screen and (max-width: 1099px){

    /* .navbar{
        width: 100%;
    } */
    .BTTitle {
        font-size: 65px;
    }
}


@media only screen and (max-width: 1024px){
    .BuyTicketContent{
        width:100%;
        margin:auto;
        /* background-color: blue; */
    }
    .BTTitleImg{
        width: 15%;
    }
    .TicketBlck{
        width: 22.6%;
    }
    .TicketBlck:hover{
        width: 22.7%;
    }
    .SideImgBuyTicket {
        /* width: 40%; */
        /* position: absolute; */
        bottom: -25%;
        z-index: 5;
        /* top:-1px; */
    }
}
@media only screen and (max-width :768px){
    
    .BTTitleBlck {
        /* background-color: purple; */
        float: left;
        margin-left: 6%;
    }
    .TicketBlck{
        width: 21.5%;
    }
    .BTTitle {
        font-size: 30px;
        /* margin: 0 1%; */
        /* margin-left: 1%; */
    }
    .BTHeadImgBlck {
        display: inline-block;
        float: none;
        /* background-color: green; */
        /* display: none; */
        /* width:2%; */
    }
    .SideImgBuyTicket {
        width: 8%;
        position: absolute;
        bottom: -65%;
        right: 2%;
        /* top:-1px; */
    }
    .BuyTicketContent{
        width:100%;
        margin:auto;
    }
    .BuyTicketsHead{
        height: 70px;
    }
    .BTTitleImg{
        width: 10%;
    }
    .BTTitleImgBlck{
        /* display: none; */
        /* background-color: blue; */

    }
}
@media only screen and (max-width: 682px){
    .TicketBlck{
        width: 90%;
    
        margin:10px;
    }
    .TicketBlck:hover {
        width: 90%;
        transform: scale(1);
    }
    .BTTitleBlck {
        /* background-color: purple; */
        float: left;
        margin-left: 6%;
    }
    .BTTitle {
        font-size: 30px;
        /* margin: 0 1%; */
        /* margin-left: 1%; */
    }
    .BTHeadImgBlck {
        display: inline-block;
        float: none;
        /* background-color: green; */
        width:30%;
    }
    .SideImgBuyTicket {
        width: 15%;
        position: relative;
        /* bottom:1px; */
        /* top:-1px; */
    }
    .BuyTicketContent{
        width:100%;
        margin:auto;
        /* background-color: blue; */
    }
    .BTTitleImgBlck{
        display: none;
    }
}
@media only screen and (max-width: 426px){
    .BTTitle {
        font-size: 35px;
    }
    .BuyTicketHeadTitleBlck{
        width:60%
    }
    .SideImgBuyTicket{
        width:50%;
        margin-left: 30%;
    }
    .BTHeadImgBlck {
        /* width: 99%; */
        /* margin-right: 10%; */
        /* float: right; */
        position: absolute;
    }
    .BuyTicketsHead{
        /* background-color: yellow; */
        height: 100px;
        position: relative;
    }
}