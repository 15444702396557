.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: beige;
  flex-direction: column;
}

.middle-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: fit-content;
  background-color: #cd0a2c;
}

.middle-container__text {
  text-align: center;
  color: white;
  font: normal normal normal 16px Norwester;
  margin-top: 5%;
  max-width: 70%;
  margin-bottom: 10px;
}

.middle-container__date-input {
  color: gray;
  border: 1px solid #ccc;
  margin-top: 2%;
  text-align: center;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  letter-spacing: 0.5px;
}

.middle-container__submit {
  color: white;
  margin-top: 2%;
  background-color: #12284c;
  border: 1px solid #ccc;
  margin-top: 2%;
  text-align: center;
  height: 40px;
  width: 140px;
  border-radius: 3px;
  opacity: 0.5;
  letter-spacing: 0.5px;
  margin-bottom: 5%;
}
