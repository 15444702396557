.Container {
    background-color: antiquewhite;
    display: flex;
    position: relative;
}
.loadingDiv {
    position: absolute;
    top: 45%;
    left: 30%;
    text-align: center;
    transform: translate(0, -50%);
}
.loadingText {
    text-align: left;
    font-family: Balgin, serif;
    font-size: 50px;
    color:white;
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin: 0;
}
.loadingSubText {
    text-align: left;
    font-family: Gosha;
    font-size: 22px;
    margin-top: 10px;
    color:black;
   /* text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin: 0; */
}
@media only screen and (max-width: 860px){
    .loadingDiv {
        position: absolute;
        top: 45%;
        left: 10%;
        text-align: center;
        transform: translate(0, -50%);
    }
   
}
@media only screen and (max-width: 430px){
    .loadingDiv {
        position: absolute;
        top: 45%;
        left: 5%;
        text-align: center;
        transform: translate(0, -50%);
    }
   
}
