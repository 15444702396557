.footer-div {
    /* text-align: center; */
    border-top:1px solid black;
  }
  .footer-logos{
      border-bottom: 1px solid black;
      padding-bottom: 10px;
  }
  .uparrow {
    text-align: center;
}
#mobile-footer{
    display: none;
}
.col {
    align-self: center;
}
.footer-links-col2{
    font-size: 16px;
    color: black;
    text-align: left;
    text-decoration: none;
}

.footer-links-col2:hover {
    text-decoration: none;
}
.footer-links{
    font-size: 16px;
    color: black;
    text-decoration: none;
}

.footer-links:hover {
    
    text-decoration: none;
}
.coldesc{
    text-align: left;
}
.test {
    text-align: left;
}
/* .test2 {
    background-color: red;
    
} */
.test3 {
    display: flex;
}
.test5 {
    margin: auto;
}
.test6 {
    margin: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.test7{
    justify-content: center;
}
img {
 object-fit: contain;   
}

.line {
    width: 100%;
    height: 1px;
    background-color: black;
}
.footer-text{
    font-size: 9px;
    color: black;
    font-family: Gosha, serif;
    text-decoration: none;
    text-align: center;
}
li {
	text-decoration: none;
}
.rights-reserved{
    font-size: 10px;
    font-family: Gosha, serif;
}
.footer-div{
    margin: auto;

}
#footer a{
    font-family: Gosha, serif;
}
.copyright-info{
    text-align: center;
    justify-content: center;
}
.oml-bottom-logo {
    display: none;
}
@media only screen and (max-width: 994px) {
    .test7 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
}
@media only screen and (max-width: 840px) {
    #paytm {
        height: 20px;
        width: 60px;
    }
    .footer-text{
        padding:0px 5px;
    }
}
@media only screen and (max-width: 769px) {
    #desktop-footer {
        display: none;

    }
    #mobile-footer {
        display: block;
        
    }
    .oml-bottom-logo {
        display: block;
        text-align: center;
    }
    .oml-top-logo {
        display: none;
    }
    .test3{
       justify-content: center;

    }
    
}

@media only screen and (max-width: 575px) {
    .uparrow {

        display: none;
    }

    .oml-bottom-logo {
        display: block;
        text-align: center;
    }
    .oml-top-logo {
        display: none;
    }
    .test7 {
        justify-content: center;
        margin-top: 15px;
    }

    .test3 {
        justify-content: center;
        margin-top: 15px;
    }
    .footer-container-custom {
        padding-bottom:60px;
    }
    .footer-logos{
        padding-bottom: 20px;
    }
    
}
@media only screen and (max-width: 426px){
    .bacardiLogo{
        margin-top:-5px;
    }
}
    
