ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
#content-cu{
    width: 80%;
    margin: auto;
    margin-top: 5%;
}
#ContactUs-content td{
    border: 1px solid black;

}
#ContactUs-content table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#ContactUs-content #tableContainer{
    /* background-color: red; */
    /* align-items: right; */
    position: absolute;
    text-align: right;
    z-index: -1;
}
.ContactUs-Title{
    font-family: Balgin, serif;
    letter-spacing: 0.05em;
    /* font-size: 450%; */
    font-size: 85px;
    letter-spacing: 0.05em;
    color:black;
    top:32%;
    z-index: 7;
    /* text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; */
    /* right: 45%; */
    
    /* ali */
    position: absolute;
    /* font-size: :; */
}

.ContactUs-Desc{
    font-family: Gosha, serif;
}
.ContactUs-Desc li{
    padding:10px;

}
.ContactUs-Desc{
    width: 100%;
    /* background-color: aqua; */
    display: block;
    width: 50%;
    margin-bottom: 200px;
    /* float: inline-start; */
}
.ContactUs-Title-Blck {
    height:150px;
    /* background-color: blue; */
    display: inline-block;
    text-align: right;
    /* float:left; */
    width:50%
}
.ContactUs-ElemsImg {
    /* background-color: red; */
    /* background: url(../images/grid.png); */
    width: 50%;
    float: right;
    /* padding-top:-20px; */
    /* height: 393px; */
    /* object-fit: ; */
    position: relative;
    
}
.bat {
    /* position: absolute; */
    position: relative;
    top:-100px;
    /* float: right; */
    left: 50%;
    /* background-color: red; */
    /* right:190px; */
    margin-bottom: 10%;
    /* top: 60px; */
    width: 30%;
}
@media only screen and (max-width: 1094px){
    #content-cu{
        width: 100%;
        /* background-color: blue; */
    }
    .ContactUs-Title{
        font-size: 78px;
    }
}
@media only screen and (max-width: 768px)
{
    .ContactUs-Title{
        font-family: Balgin, serif;
        letter-spacing: 0.05em;

        /* font-size: 450%; */
        font-size: 60px;
        position: relative;

    }
    .ContactUs-Title-Blck {
        height:40px;
        height:auto;

        /* background-color: blue; */
        display: inline-block;
        /* text-align: center; */
        top : auto;
        text-align: left;

        width: 80%;
        margin-left: 10%;
        /* float:left; */
    
    }
    .ContactUs-Desc li{
        text-align: left;
    }
    .ContactUs-ElemsImg{
        /* display: none; */
        position: relative;
        float: none;
        width: 100%;
    }
    .ContactUs-Desc{
        /* width: %; */
        align-items: center;
        text-align: center;
        justify-content: left;
        /* background-color: aqua; */
        display: block;
        /* margin-left: 5%;
        width: 95%; */
        margin-bottom: 200px;
        z-index: 6;
        /* float: inline-start; */
    }
}
@media only screen and (max-width: 490px){
    #content-cu td{
        border: 1px solid black;
        opacity: 0.3;
    }
    #content-cu table{
        border-collapse: collapse;
        margin-left: auto;
        opacity: 0.3;

        /* position: absolute; */
        top: 0;
    }
    .ContactUs-ElemsImg{
        /* display: none; */
        position: relative;
        float: none;
        width: 100%;
    }
    .ContactUs-Title{
        font-family: Balgin, serif;
        letter-spacing: 0.05em;

        /* font-size: 450%; */
        font-size: 40px;
        position: relative;

    }
    .ContactUs-Title-Blck {
        height:40px;
        height:auto;

        /* background-color: blue; */
        display: inline-block;
        /* text-align: center; */
        top : auto;
        text-align: left;

        /* width: 100%; */
        /* float:left; */
    
    }
    .ContactUs-Desc li{
        text-align: left;
        list-style: none;
    }
   
    .ContactUs-Desc{
        /* width: %; */
        align-items: center;
        text-align: center;
        justify-content: left;
        /* background-color: aqua; */
        display: block;
        /* margin-left: 5%; */
        width: 100%;
        margin-bottom: 200px;
        z-index: 6;
        
        /* float: inline-start; */
    }
    #tableContainer{
        /* background-color: red; */
        /* align-items: right; */
        position: absolute;
        text-align: center;
        z-index: -1;
    }
  

}