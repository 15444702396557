.FAQHead{
    /* background-color: green; */
    margin-top: 10px;
    width: 80%;
    margin: auto;
}
.FAQBlck{
    /* display: inline-block; */
    width:32.5%;
    margin: 0px 0.05%;
    margin-bottom: 1%;
}
#FAQContent td{
    border: 1px solid black;

}
#FAQContent table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#FAQContent #tableContainer{
    /* background-color: red; */
    align-items: right;
    position: absolute;
    text-align: right;
    z-index: -1;
}
.SideImgfaq {
    /* display: inline-block; */
    /* width: 20%; */
    float: right;
    margin-right: 10%;
    width: 50%;
    /* background-color: blue; */
}
.FAQImg {
    /* margin: 10px; */
    width: 90%;
    /* height:  */
}
.FaqHeadTitleBlck {
    float: left;
    width: 50%;
    /* background-color: yellow; */
}
.FaqHeadImgBlck {
    position: relative;
    display: inline-block;
    width: 50%;
    /* background-color: red; */
}
.FAQContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}
.FAQPage {
    /* align-items: ; */
    margin: auto;
    margin-bottom: 100px;

}
.TitleBlck{
    /* float: left; */
    display: inline-block;
    /* background-color: blue; */
    /* margin-left: 15%; */
    /* margin-right: 1%; */
    /* width: 30%; */
}
.TitleImgBlck{
    display: inline-block;
    /* width: 60%; */
    /* background-color: cyan; */
    height: 100%;
}
.FaqTitle{
    font-family: Balgin, serif;
    font-size: 100px;
    color:white;
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin: 0;
}
.FaqTitleImg{
    width:80%;
    /* height: 100%; */
    margin-left: 10%;
    /* margin-top:10% */
    /* height: ; */
}
@media only screen and (max-width: 582px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 45px;
    }
    .FaqHeadTitleBlck {
        float: left;
        width: 40%;
        /* background-color: yellow; */
    }
    .FAQBlck{
        /* display: inline-block; */
        width:80%;
        margin-left: 10%;
        /* align-items: center; */
        /* margin: 0px 0.05%; */
        margin-bottom: 1%;
    }
    .TitleImgBlck{
        display: none;
    }
}

@media only screen and (max-width: 1010px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 100px;
    }
    .FaqHeadTitleBlck {
        float: left;
        width: 40%;
        /* background-color: yellow; */
    }
    .FAQBlck{
        /* display: inline-block; */
        width:80%;
        margin-left: 10%;
        /* align-items: center; */
        /* margin: 0px 0.05%; */
        margin-bottom: 1%;
    }
    .TitleImgBlck{
        display: none;
    }
    .FaqHeadImgBlck {
        width: 50%;
    }
}
@media only screen and (max-width: 950px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 95px;
    }
   
}
@media only screen and (max-width: 825px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 85px;
    }
   
}
@media only screen and (max-width: 705px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 80px;
    }
   
}
@media only screen and (max-width: 600px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 70px;
    }
   
}
@media only screen and (max-width: 550px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 60px;
    }
   
}
@media only screen and (max-width: 450px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 50px;
        margin-bottom: 5%;
    }
   .SideImgfaq {
       /* height: 100px; */
       width: 150px;
   }
   .FaqHeadImgBlck{
       margin-left: 20px;
   }
}

@media only screen and (max-width: 320px){
    .FaqTitle{
        font-family: Balgin, serif;
        font-size: 45px;
        margin-bottom: 5%;
    }
   .SideImgfaq {
       /* height: 100px; */
       width: 100px;
   }
   .FaqHeadImgBlck{
       margin-left: 25px;
   }
}