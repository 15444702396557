.box1 {
    height: 100%;
    width: 100%;
    margin: auto;
    /* background-color: chocolate; */
    background: url('../finalRenders/GIF-1920-1080.gif');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}
.ticketOverlay a{
    /* background-color: rgb(250, 250, 243); */
    height:150px;
    width:200px;
}
#store_overLay_desktop{
    /* background-color: blue; */
    width: 150px;
    height: 150px;
    position: absolute;
    left: 33%;
    bottom: 260px;
}

#music_overLay_desktop{
    /* background-color: pink; */
    width: 190px;
    height: 110px;
    position: absolute;
    right: 7%;
    bottom: 10%;
}
#food_overLay_desktop{
    /* background-color: brown; */
    width: 152px;
    height: 110px;
    position: absolute;
    right: 34%;
    bottom: 36%;
}
#photo_overLay_desktop{
    /* background-color: orange; */
    width: 150px;
    height: 155px;
    position: absolute;
    right: 22%;
    bottom: 12%;

}
.ticketOverlay{
    /* background-color: red; */
    cursor: pointer;
    height:150px;
    width:200px;
    position: absolute;
    bottom:10%;
    margin-left: 7%;
    /* position:absolute */
}
#vid{
    align-self: center;
    object-fit: cover;
    object-position: bottom;
}
.tipicon {
    height: 90px;
    width: 90px;
}
.score-board {
    width: 500px;
    border-radius: 10px;
    background-color:#ff3c3c;
    position: fixed;
    z-index: 500;
    font-family: Gosha;
    bottom: 10%;
    display: none;
    right: 0;
    padding: 10px;
    animation: displayScore 0.5s ease-in;
}
.score-board ul li {
    text-align: left
}
.score-board-title {
    font-family: Balgin, serif;
    font-size: 24px;
    color:white;
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin: 0;

}
@keyframes displayScore {
    0%   { right:-500px}
    10%   { right:-380px}
    15%   { right:-260px}
    25%  {right:-170px}
    35%   { right:-130px}
    45%   { right:-110px}
    50%  { right:-100px}
    60%   { right:-80px}
    70%   { right:-60px}
    75%  { right:-50px}
    80%   { right:-30px}
    90%   { right:-20px}
    100% { right:0px}
  }
  @keyframes displayScoreRev {
    100%   { right:-500px}
    90%   { right:-380px}
    80%   { right:-260px}
    75%  {right:-170px}
    70%   { right:-130px}
    60%   { right:-110px}
    50%  { right:-100px}
    45%   { right:-80px}
    35%   { right:-60px}
    25%  { right:-50px}
    15%   { right:-30px}
    10%   { right:-20px}
    0% { right:0px}
  }
.floatingIcon {
    width: 50px;
    position: fixed;
    z-index: 500;
    text-align: center;
    bottom: 12%;
    right: 35px;
}
.score-board-text {
    font-size: 16px;
    text-align: center;
    font-family: Gosha;
}
.homeParent {
    
    height: 80%;
    width: 100%;
    /* background-color: red; */
}
.box1 {
    width: 100%;
    height: inherit;
    /* object-fit: cover; */
    /* height: 500px; */
}
.OtherOverlay{
    background-color: beige;
    position: absolute;

}
.foodOverLay{
    /* background-color: red; */
    height:200px;
    width: 200px;
    position: absolute;
    margin-left: 45%;
    margin-top: 25.8%;
    cursor: pointer;
}
.foodOverLay span{
    border-radius: 100px;
    padding:5px;
    font-size: 15;
    /* opacity: 0.5; */
    margin-top: 20%;
    /* background-color: black; */
    color:white;
}
#breezer_overLay_desktop {
    /* background-color: green; */
    width: 160px;
    height: 215px;
    position: absolute;
    left: 20%;
    bottom: 260px;

}
#breezer_bar_overLay_desktop{
    /* background-color: lightgreen; */
    width: 95px;
    height: 150px;
    position: absolute;
    left: 13%;
    bottom: 260px;
}
#dewar_overLay_desktop {
    /* background-color: coral; */
    width: 190px;
    height: 215px;
    position: absolute;
    right: 18%;
    bottom: 260px;

}
#dewar_bar_overLay_desktop{
    
    /* background-color: lightcoral; */
    width: 95px;
    height: 150px;
    position: absolute;
    right: 10%;
    bottom: 260px;
}
#barcardi_overLay_desktop {
    /* background-color: black; */
    width: 235px;
    height: 200px;
    position: absolute;
    right: 47%;
    bottom: 50px;
}
#barcardi_bar_overLay_desktop{
    /* background-color: gray; */
    width: 95px;
    height: 185px;
    position: absolute;
    right: 40%;
    bottom: 50px;
}
@media only screen and (max-width: 1025px){
    .ticketOverlay{
        height:150px;
        width:200px;
        position: absolute;
        bottom: 3%;
        margin-left: 5%;
        /* position:absolute */
    }

    #music_overLay_desktop{
        width: 145px;
        height: 100px;
        position: absolute;
        right: 2%;
        bottom: 9%;
    }

    #store_overLay_desktop {
        width: 120px;
        height: 130px;
        position: absolute;
        left: 30%;
        bottom: 212px;
    }
    #photo_overLay_desktop {
        width: 140px;
        height: 140px;
        position: absolute;
        right: 18%;
        bottom: 12%;
    }
    #food_overLay_desktop {
        width: 115px;
    height: 113px;
    position: absolute;
    right: 33%;
    bottom: 35%;
    }
    #breezer_overLay_desktop {
        width: 130px;
        height: 185px;
        position: absolute;
        left: 16%;
        bottom: 210px;
    }
    #breezer_bar_overLay_desktop{
    width: 95px;
    height: 150px;
    position: absolute;
    left: 6%;
    bottom: 210px;
    }
    #dewar_overLay_desktop {
        width: 190px;
        height: 176px;
        position: absolute;
        right: 12%;
        bottom: 220px;
    }
    #dewar_bar_overLay_desktop{
    width: 95px;
    height: 150px;
    position: absolute;
    right: 2%;
    bottom: 200px;
    }
    #barcardi_overLay_desktop {
    width: 190px;
    height: 175px;
    position: absolute;
    right: 46%;
    bottom: 35px;
    }
    #barcardi_bar_overLay_desktop{
    width: 100px;
    height: 180px;
    position: absolute;
    right: 36%;
    bottom: 50px;
    }
}
@media only screen and (max-width: 769px){
    #store_overLay_desktop{
        width: 140px;
        height: 130px;
        left: 30px;
        bottom: 400px;
    }
    .box1 {
        /*height: 100%;*/
        /*width: 100%;*/
        margin: auto;
        /* background-color: chocolate; */
        background: url('../finalRenders/GIF-1920_1080.gif');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
	.ticketOverlay{
	    /* background-color: red; */
		/* display:none; */
        /* position:absolute */
        width: 160px;
        height: 180px;
        left: 250px;
        bottom: 140px;
	}
	.foodOverLay span{
		display:none;
	}
    #vid{
        align-self: center;
        object-fit: cover;
    }
    .score-board {
        width: 260px;
        border-radius: 10px;
        position: fixed;
        z-index: 500;
        bottom: 12%;
        right: 0;
    }
    .floatingIcon {
        width: 50px;
        position: fixed;
        z-index: 500;
        text-align: center;
        bottom: 12%;
        right: 35px;
    }
    
    #music_overLay_desktop{
        width: 160px;
        height: 120px;
        position: absolute;
        left: 8%;
        bottom: 200px;
    }
    #photo_overLay_desktop {
        width: 150px;
        height: 180px;
        position: absolute;
        right: 10%;
        bottom: 25%;
    }
    #food_overLay_desktop {
        width: 120px;
        height: 140px;
        position: absolute;
        right: 8%;
        bottom: 52%;
    }
    #breezer_overLay_desktop {
        width: 130px;
        height: 185px;
        position: absolute;
        left: 16%;
        bottom: 210px;
    }
    #breezer_bar_overLay_desktop{
        /* background-color: pink; */
        width: 95px;
        height: 150px;
        position: absolute;
        left: 29%;
        bottom: 540px;
    }
    #dewar_overLay_desktop {
        width: 190px;
        height: 176px;
        position: absolute;
        right: 3%;
        bottom: 80%;
    }
    #dewar_bar_overLay_desktop{
        /* background-color: red; */
        width: 95px;
        height: 150px;
        position: absolute;
        right: 28%;
        bottom: 540px;
    }
    #barcardi_overLay_desktop {
        width: 229px;
        height: 220px;
        position: absolute;
        right: 41%;
        bottom: 50%;
    }
    #barcardi_bar_overLay_desktop{
        width: 100px;
        height: 185px;
        position: absolute;
        right: 28%;
        bottom: 55%;
    }

}
@media only screen and (max-width: 426px)
{
    #store_overLay_desktop{
        width: 85px;
        height: 65px;
        left: 0;
        bottom: 225px;
    }
    .ticketOverlay{
        /* display: block; */
        height:120px;
        width:100px;
        position: absolute;
        bottom: 60px;
        left: 120px;
        /* margin-left: 11%; */
    }
    .score-board {
        width: 250px;
        height: 400px;
        overflow: scroll;
        border-radius: 10px;
        position: fixed;
        z-index: 500;
        bottom: 12%;
        right: 0;
    }
    .score-board-text {
        font-size: 12px;
        text-align: center;
        font-family: Gosha;
    }
    .floatingIcon {
        width: 50px;
        position: fixed;
        z-index: 500;
        text-align: center;
        bottom: 12%;
        right: 35px;
    }
    
#music_overLay_desktop{
    width: 120px;
    height: 100px;
    position: absolute;
    left: 2%;
    bottom: 12%;
}
#food_overLay_desktop {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 2%;
    bottom: 27%;
}
#photo_overLay_desktop {
    width: 100px;
    height: 140px;
    position: absolute;
    right: 7%;
    bottom: 8%;
}

#breezer_overLay_desktop {
    width: 100px;
    height: 115px;
    position: absolute;
    left: 3%;
    bottom: 300px;
}
#breezer_bar_overLay_desktop{
    width: 55px;
    height: 100px;
    position: absolute;
    left: 28%;
    bottom: 310px;
}
#dewar_overLay_desktop {
   
    width: 91px;
    height: 95px;
    position: absolute;
    right: 5%;
    bottom: 300px;

}
#dewar_bar_overLay_desktop{
    width: 55px;
    height: 95px;
    position: absolute;
    right: 28%;
    bottom: 300px;
}
#barcardi_overLay_desktop {
    width: 150px;
    height: 110px;
    position: absolute;
    right: 41%;
    bottom: 28%;
}
#barcardi_bar_overLay_desktop{
    width: 50px;
    height: 100px;
    position: absolute;
    right: 29%;
    bottom: 29%;
}

}
.modal-close-btn {
    height: 50px;
    width: 50px;
    position: absolute;
    right: 25px;
    z-index: 50;
    top: 25px;
  
  }

  

@media only screen and (max-width:376px){
    #food_overLay_desktop {
        width: 85px;
        height: 80px;
        position: absolute;
        right: 1%;
        bottom: 27%;
    
    }
    #music_overLay_desktop {
        width: 120px;
        height: 75px;
        position: absolute;
        left: 2%;
        bottom: 12%;
    }
    #store_overLay_desktop {
        width: 60px;
        height: 50px;
        left: 2px;
        bottom: 205px;
    }
    #photo_overLay_desktop {
        width: 100px;
        height: 110px;
        position: absolute;
        right: 2%;
        bottom: 9%;
    }
    .ticketOverlay {
        /* display: block; */
        height: 120px;
        width: 100px;
        position: absolute;
        bottom: 30px;
        left: 120px;
        /* margin-left: 11%; */
    }
    #breezer_overLay_desktop {
        width: 85px;
        height: 95px;
        position: absolute;
        right: 40%;
        bottom: 40%;
    }
    #breezer_bar_overLay_desktop{
        width: 55px;
        height: 100px;
        position: absolute;
        left: 28%;
        bottom: 270px;
    }
    #dewar_overLay_desktop {
        width: 91px;
        height: 100px;
        position: absolute;
        right: 3%;
        bottom: 275px;
    }
    #dewar_bar_overLay_desktop{
        width: 55px;
    height: 95px;
    position: absolute;
    right: 28%;
    bottom: 280px;
    }
    #barcardi_overLay_desktop {
        width: 120px;
    height: 95px;
    position: absolute;
    right: 41%;
    bottom: 25%;

    }
    #barcardi_bar_overLay_desktop{
        width: 50px;
        height: 100px;
        position: absolute;
        right: 28%;
        bottom: 25%;    
    }
}
@media only screen and (max-width:321px) {
        
#music_overLay_desktop{
    /* background-color: pink; */
    width: 100px;
    height: 80px;
    position: absolute;
    left: 0%;
    bottom: 15%;
}
.ticketOverlay {
    /* display: block; */
    height: 100px;
    width: 100px;
    position: absolute;
    bottom: 85px;
    left: 89px;
    /* margin-left: 11%; */
}
#photo_overLay_desktop {
    width: 80px;
    height: 110px;
    position: absolute;
    right: 0%;
    bottom: 14%;
}
#store_overLay_desktop {
    width: 50px;
    height: 50px;
    left: 0px;
    bottom: 210px;
}
#food_overLay_desktop {
    width: 55px;
    height: 80px;
    position: absolute;
    right: 0%;
    bottom: 30%;
}
#breezer_overLay_desktop {
    left: 0%;
    height: 85px;
}
#breezer_bar_overLay_desktop{

}
#dewar_overLay_desktop {

}
#dewar_bar_overLay_desktop{
    
}
#barcardi_overLay_desktop {
    width: 110px;
}
#barcardi_bar_overLay_desktop{
    
}
}