.parent-row {
    
    width: 100%;
    margin: auto;
    text-align: center;
}
.frame-img {
    object-fit: contain;
    /* height: 500px; */
}
#camera-wrapper{
    position: relative;
}
#clickPhto{
    cursor: pointer;
    /* opacity: 0.5; */
}
 
#clickPhto:hover{
    transform: scale(1.4);
}
#upperBtns{
    position: absolute;
    z-index: 1;
    width: 90%;
}
#download-btn, #like-it-btn{
    display: inline-block;
}
#dnldBtn{
    /* background-color: blue; */
    color:white;
    /* padding:10px; */
    margin:10px;
    border-radius: 5px;
}
#take-pic-btn{
    position: absolute;
    z-index: 1;
    right: 0;
}
.btns {
    /* display: flex; */
    align-content: center;
    text-align: center;
    /* justify-content: space-evenly; */
    /* background-color: blue; */
}
/* .frames-ul */
.frames-ul{
    justify-content: center;
    align-self: center;
}
.frames-ul li{
    display: inline-block;
    margin-left: 10px;
    /* padding: 20px; */
}
.frames-ul li input{
    padding-left:15px;
    padding-right:15px;

}

#camera-video{
    /* height: 500px; */
    
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);  
    position: absolute;
}
#photoBoothPage .row , #photoBoothPage .mt-2, #photoBoothPage .parent-row{
    margin: 0!important;
    padding:0!important;
}
.photoBoothPage{
    /* background-color: white;
    z-index: -5000; */
}

#btnicon:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 1024px){

    #btnicon{
        /* background-color: red; */
        height:55px;
        width:55px;
        /* position:absolute */
    }
    .frames-ul{
        margin-top:50px
    }
}

@media only screen and (max-width: 767px){

    #btnicon{
        /* background-color: red; */
        height:50px;
        width:50px;
        /* position:absolute */
    }
    #btnicon:hover {
        transform: scale(1);
    }
    
}
@media only screen and (max-width: 426px){
   
    #close-btn{
        /* background-color: red; */
        height:50px;
        width:50px;
        /* position:absolute */
    }
    #btnicon{
        /* background-color: red; */
        transform: scale(1.2);
        /* position:absolute */
    }
}