@font-face{
    font-family: 'Balgin';
    src: local('Balgin'), url(../fonts/Balgin/Balgin\ Display\ Extra\ Bold.ttf) format('truetype')
}
/* @font-face{
    font-family: 'Balgin Medium';
    src: local('Balgin Medium'), url(../fonts/Balgin/Balgin\ Display\ Medium.ttf) format('truetype')
} */
@font-face{
    font-family: 'Gosha';
    src: local('Gosha'), url(../fonts/Gosha\ Sans/TTF/GoshaSans-Medium.ttf) format('truetype')
}
#close-navbar-inactive{
    display: none;
}
.navbar-header{
    position: relative;
    /* background-color: blue; */
    margin-top:3%;
    width: 80%;
    margin-left: 10%;
    height: 150px;
    z-index: 10;
}
span {
    text-decoration: none;

}
span:hover {
    text-decoration: none;
}
.NavbarLogoaa {
    /* display: inline-block; */
    /* margin-left: 10%; */
    /* background-color: orange; */
    float: left;
    width: 20%;
}
.mobileNavIcon{
    display: none;
}
.NavbarLinks {
    display: inline-block;
    /* align-items: center; */
    /* width:700px; */  
    line-height: 120px;
    text-align: right;
    /* margin-right: 10%; */
    width: 80%
    /* background-color: red; */
    /* float: right; */
    
}
a {
    text-decoration: none;
    color:black;
    /* background-color: green; */
}

a:hover {
    text-decoration: none;
    color:black;
}
li {
    list-style: none;
}
.LogoImg-header {
    /* padding:20px; */
    /* display: inline-block; */
    height: 45%;
    width: 55%;
    object-fit: cover;
    /* background-color: green; */

}
.over-the-years-active a{
    background-color: #0098bc;
    border-radius: 1000px;
    color:white;
    border: 1px solid black;

}
.contact-us-active a{
    background-color: #a668ba;
    border-radius: 1000px;
    /* color:white; */
    border: 1px solid black;
}
.buy-ticket-active a{
    background-color: #30b88d;
    color:white;
    border-radius: 1000px;
    border: 1px solid black;
}
.lineup-active a {
    background-color: #ffdd46;
    color:black;
    border-radius: 10000px;
    border: 1px solid black;
}
.faq-active a{
    background-color: #ff6746;
    color:white;
    border-radius: 10000px;
    border: 1px solid black;

}
.Navbar-Link-li{
    display: inline-block;
    padding-left:20px;
    text-align: center;
    /* padding-right: 10px; */
    text-decoration: none;
    font-family: Gosha, serif;
}

.NavbarLinks li a{
    padding:8px 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 105%;
}
.NavbarLinks li a:hover{
    /* text-decoration: underline; */
    border-spacing: 5px;
    /* padding: 8px 0px; */
    color: black;
    text-decoration: none;
    border-bottom: 1px solid black;
}
.RevealLinks-header{
    display:none;
}
#comingSoonDiv{
    position: absolute;
    /* padding:10px; */
    /* height: 40px; */
    margin-top: 2.5%;
    margin-left: -6.5%;
    /* background-color: black; */
    /* opacity: 0.5; */
    color:white;
    /* border-radius: 300px; */
}
@media only screen and (max-width: 1199px){

    /* .navbar{
        width: 100%;
    } */
    
    .Navbar-Link-li{
        padding-left:10px;
    }
    .NavItemHolder {
        margin: auto;
        text-align: center;
    }
   span {
       width: 100%;
   }
   .close-btn {
    position: absolute;
    width: 100%;
    top: 5%;
    margin: auto;
}
/* #coming-soon-link{
    background-color: red;
    position: absolute;

} */
}
@media only screen and (max-width: 1099px){

    /* .navbar{
        width: 100%;
    } */
    .Navbar-Link-li{
    
        padding-left:5px;
    }
    .NavItemHolder {
        margin:auto;
        text-align: center;
    }
    .close-btn {
        position: absolute;
        width: 100%;
        top: 5%;
        margin: auto;
    }
}
@media only screen and (max-width: 1046px) {
    #comingSoonDiv{
        position: fixed;
        
        /* padding:10px; */
        /* height: 40px; */
        /* margin-top: 2.5%; */
        /* margin-left: -10.5%; */
        /* background-color: black; */
        /* opacity: 0.5; */
        /* font-size: 10px; */
        margin-left: -54%;
        margin-top: -1%;
        font-size: 14px;
        /* border-radius: 300px; */
    }
}
@media only screen and (max-width: 1137px){
    .navbar{
        width: 100%;
        margin:0;

    }
}
@media only screen and (max-width: 1057px){

    body{
        /* background-color: yellow; */
    }
    .navbar{
        width: 100%;
        height: 100%;
        margin:0;
    }
    .navbtn{
        height: 100%;
    }
    .hideLinks {
        display: none;
    }
    .NavbarLinks-show li span{
        padding:8px 20px;
        cursor: pointer;
        text-align: center;
        font-size: 25px;
    }
    .Navbar-Link-li-show{
        display: block;
        /* position: absolute; */
        /* height: 100%; */
        z-index: 11;
    }
    .RevealLinks-header{
        display: block;
        background-color: #ffdd46;
        width: 100%;
        height: 10%;
        bottom: 0px;
        align-self: center;
        text-align: center;
        position: fixed;
        left:0;
    }
   
    .NavbarLinks-show {
        display: flex;
        position: fixed;
        top:0;
        left: 0;
        /* align-items: center; */
        background-color: #ffdd46;
        /* width:700px; */  
        /* line-height: 120px;
        margin-right: 10%; */
        width: 100%;
        height: 100%;
        /* background-color: red; */
        /* float: right; */
    }
    #burger button{
        border:none;
        background-color: #ffdd46;
        justify-content: center;
        margin-top: auto;
    }
    .NavbarLinks-show li{
        display: flex;
        top:0;
        background-color: #ffdd46;
        /* width: 100%; */
        /* margin-left: 20%; */
        align-self: center;
        text-align: center;
        /* border-bottom: 0.1px solid grey; */
        /* height: 14%; */
        padding: 15px;

        align-items: center;
        /* padding:14.7% 0; */

        /* height: 15%; */
        /* padding:5%; */
    }
    .NavbarLinks-show ul{
        /* background-color: pink; */
        /* height: 80%; */
        align-self: center;
        width: 100%;

        /* margin-top: 15%; */
        /* vertical-align: middle; */
        /* margin-top: 15%; */
    }
    .NavbarLinks {
        display: none;
        position: fixed;
        
        /* align-items: center; */
        /* width:700px; */  
        /* line-height: 120px;
        margin-right: 10%; */
        width: 100%
        /* background-color: red; */
        /* float: right; */
    }
    #close-navbar-active{
        display: block;
    }
    .NavbarLogoaa {
        float: none;
        margin-top:5%;
        width: 100%;
    }
    .LogoImg-header {
        /* align-self: center; */
        /* align */
        height: 20%;
        width: 20%;
        margin-left: 10%;
        /* object-fit: cover; */
    /* background-color: green; */

    }
}


@keyframes navbar-animation {
    from{
        height:0px;

    }
    to{
        height: 100%;
    }
}