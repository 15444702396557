* {
    margin:0;
    padding:0;
    box-sizing: border-box;
}

body {
    background-color: skyblue;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;
  }
  .grid-item {
    /* background-color: orange; */
    font-size: 16px;
    margin: 5px;
    text-align: center;
  }
.flexbox {
    display:flex;
    justify-content: space-evenly;
    width: 100%;
  /* background-color: brown; */
    /* max-width: 768px; */
    height: 100vh;

    overflow: hidden;
    
    margin-top: 75px;
    padding: 15px;
    align-items: center;
}
.flexbox .board456 {
  display: flex;
  flex-direction: column;
  z-index: 50;
  width: 100%;
  padding: 15px;
}
.flexbox .board {
  display: flex;
  flex-direction: column;
  
  width: 100%;
  margin: 15px;
  height:10vh;
  background-color: aquamarine;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 15px;
}

.cards {
  display: flex;
  flex-direction: column;
  
  width: 100%;
}


.flexbox .cards .card {
    padding:2px;
    background-color: orange;
    cursor: pointer;
    width: 100%;
  margin: 15px;
  height:10vh;
    /* margin-bottom: 15px; */
}

.card-size {
  height: 50px;
  width: 10px;
  background-color: yellow;
}

.testing {
  position: relative;
}

.testing1 {
  position: absolute;
}
.music-player {
  height: 50px;
}
.audio-player {
  width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 15px;
}
#audio {
  display: none;
}
.music-title {
  display: flex;
  justify-content: space-around;
}

@keyframes zoominout {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.2,1.2);
  }
  100% {
      transform: scale(1,1);
  }
}

.bounce {
  animation: zoominout 1s infinite ;
}

