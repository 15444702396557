.line-up-title{
    font-family: Balgin, serif;
    font-size: 100px;
    color:white;
    letter-spacing: 0.05em;
    text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    margin: 0;
}
.lineuptest {
    background-color: red;
    
}
.LineUpPage {
    position: relative;    
}

.lineuptest2 {
    width: 100%;
    text-align: end;
    left: 10px;

}

#lineUpContent td{
    border: 1px solid black;

}
#lineUpContent table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#lineUpContent #tableContainer{
    /* background-color: red; */
    /* align-items: right; */
    position: absolute;
    text-align: right;
    z-index: -1;
}


td{
    border: 1px solid black;

}
#lineUpContent2 table{
    border-collapse: collapse;
    margin-left: auto;
    /* position: absolute; */
    top: 0;
}
#lineUpContent2 #tableContainer{
    /* background-color: red; */
    /* align-items: right; */
    position: absolute;
    text-align: right;
    z-index: -1;
}


.line-up-mobile-img {
    display: none;
}
.align-img {
    text-align: center;
}

@media only screen and (max-width: 430px){
    .line-up-mobile-img {
        display: block;
    }
    .line-up-desktop-img {
        display: none;
    }
   
}
@media only screen and (max-width: 582px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 40px;
    }
   
}

@media only screen and (max-width: 1010px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 95px;
    }
   
}
@media only screen and (max-width: 950px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 90px;
    }
   
}
@media only screen and (max-width: 825px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 75px;
    }
   
}
@media only screen and (max-width: 705px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 70px;
    }
   
}
@media only screen and (max-width: 600px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 65px;
    }
   
}
@media only screen and (max-width: 550px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 45px;
    }
   
}
@media only screen and (max-width: 450px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 35px;
        margin-bottom: 5%;
    }
  
	.line-up-mobile-img {
	    display: block;
	}
	.line-up-desktop-img{
		display:none;
	}
}

@media only screen and (max-width: 320px){
    .line-up-title{
        font-family: Balgin, serif;
        font-size: 30px;
        margin-bottom: 5%;
        padding-right: 5px;
    }

}
